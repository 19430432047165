import React, { useContext } from 'react';
import { Form } from '../../../../Components/Form';
import { Link, useHistory } from 'react-router-dom';
import { AptorApi } from '../../../../Api';
import { useIntl } from 'react-intl';
import { useFormField } from '../../../../Components/Form/FormField';
import { FormValues } from '../../../../Components/Form/Form.types';
import { Typography, Divider } from '@material-ui/core';
import { useStyles } from './Help.styles';
import { languageType, UserContext } from '../../../../Context/UserContext/UserContext';

export const HelpPanel = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const user = useContext(UserContext);

  const descriptionField = useFormField({
    label: formatMessage({ id: 'form.helpDescription' }),
    name: 'description',
    type: 'text',
    multilineRows: 5,
    required: true,
  });

  const validFromDate = useFormField({
    label: formatMessage({ id: 'form.validFrom' }),
    name: 'validFromUTC',
    type: 'datetime',
    required: true,
  });

  const validToDate = useFormField({
    label: formatMessage({ id: 'form.validTo' }),
    name: 'validToUTC',
    type: 'datetime',
    required: true,
  });

  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => {
    await api.supportInvite(data);
    onSuccess('law-portal.settings.invitationSent');
    history.push('/law-portal/help');
    descriptionField.clear();
    validFromDate.clear();
    validToDate.clear();
  };

  return (
    <>
      <Typography className={classes.description} variant="body2">
        {formatMessage({ id: 'law-portal.settings.help.description' })}
      </Typography>
      <Typography className={classes.description} variant="body2">
        <a
          href={"https://help.lagportalen.se/sv"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {formatMessage({ id: 'law-portal.settings.help.description.link' })}
        </a>
      </Typography>
      <Typography className={classes.description} variant="body2">
        {formatMessage({ id: 'law-portal.settings.help.description.next' })}
      </Typography>
      <Divider className={classes.divider} />
      <Form
        submit={handleSubmit}
        submitLabel={formatMessage({ id: 'form.send' })}
        groups={[
          {
            grid: {
              type: 'column',

              items: [
                { type: 'row', items: [descriptionField] },
                { type: 'row', items: [validFromDate, validToDate] }
              ],
            },
          },
        ]}
      />
    </>
  );
};
