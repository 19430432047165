import React, { useContext, useEffect } from 'react';
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { getAppConfiguration } from "./Config";
import { getAuthProvider } from './Auth/AuthProvider';
import { createBrowserHistory } from 'history';
import { Route, Router, Switch } from 'react-router-dom';
import { Home, IntlProvider, Transition } from './Components';
import { ImpersonationBar } from './Components/ImpersonationBar/ImpersonationBar';
import { IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import LawPortal from './Modules/LawPortal';
import Administration from './Modules/Administration';
import AptorAdmin from './Modules/AptorAdmin';
import { SnackbarProvider } from 'notistack';
import { UserContext, useUser, Module, LawPortalFeature, AccessLevel } from './Context/UserContext/UserContext';
import { ConfirmationProvider } from './Context/ConfirmationContext/ConfirmationContext';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Intercom,{shutdown} from '@intercom/messenger-js-sdk';
import  CryptoJS from 'crypto-js'

const browserHistory = createBrowserHistory();
const hash = window.location.hash;
const containsIdTokenHint = hash.indexOf('id_token_hint=') >= 0;
const forgotPasswordKey = "userForgotPassword";
const cancelledForgotPassword = hash.indexOf("AADB2C90091") >= 0;
const containsIdToken = hash.indexOf("id_token=") >= 0;
let idTokenHint = undefined as any;
if (containsIdTokenHint) {
  idTokenHint = hash
    .replace("#", "")
    .split("&")
    .find((seg) => seg.indexOf("id_token_hint=") >= 0);
}
const forgotPassword =
  !containsIdToken &&
  !cancelledForgotPassword &&
  (localStorage.getItem(forgotPasswordKey) === "1" ||
    hash.indexOf("AADB2C90118") >= 0);
const AppRouter = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const accessTokenRequest = {
    scopes: getAppConfiguration().scopes,
    account: accounts[0],
  };
  const { hasAccessToModule, hasAccessToFeature, authorizationPending, fetchUser } = useContext(UserContext);
  const accessToken = localStorage.getItem('accessToken')
  useEffect(() => {
    if (!containsIdTokenHint) {
      if (!isAuthenticated) {
        loginHandle();
      }
    }
    instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
      localStorage?.setItem("accessToken", accessTokenResponse?.accessToken)
      if (accessToken == null) {
        fetchUser()
      }
    }).catch(error => { console.log(error, "token error") })
  }, [])

  const loginHandle = async () => {
    await instance.handleRedirectPromise();
    if (accounts.length === 0) {
      await instance.loginRedirect({
        scopes: getAppConfiguration().scopes
      })
    }
  }

  return (
    (authorizationPending && <></>) || (
      <Router history={browserHistory}>
        <Switch>
          {hasAccessToModule(Module.LawPortal) && <Route path="/law-portal" component={LawPortal} />}
          {(hasAccessToModule(Module.Organization) ||
            hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Read)) && (
              <Route path="/organization" component={Administration} />
            )}
          {hasAccessToModule(Module.Administration) && <Route path="/admin" component={AptorAdmin} />}
          <Route path="/forbidden" component={() => <Home forbidden />} />

          <Route path="/" component={Home} />
        </Switch>
      </Router>
    )
  );
};

const AppContainer = () => {
  const { authorizationPending, authorizationFailed, serverError, supportInvitation, userImpersonation } = useContext(
    UserContext,
  );

  return (
    <>
      {!authorizationFailed && !serverError && (supportInvitation !== undefined || userImpersonation !== undefined) && (
        <Transition isLoading={authorizationPending}>
          <ImpersonationBar />
        </Transition>
      )}
      {!authorizationFailed && !serverError && (
        <Transition isLoading={authorizationPending}>
          <AppRouter />
        </Transition>
      )}

      {authorizationFailed && (
        <Transition isLoading={authorizationPending}>
          <Home notAuthorized />
          {(supportInvitation !== undefined || userImpersonation !== undefined) && <ImpersonationBar />}
        </Transition>
      )}

      {serverError && (
        <Transition isLoading={authorizationPending}>
          <Home serverError />
        </Transition>
      )}
    </>
  );
};

const cookieCleaner = () => {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie =
      name +
      "=;" +
      "expires=Thu, 01-Jan-1970 00:00:01 GMT;" +
      "path=" +
      "/;" +
      "domain=" +
      window.location.host +
      ";" +
      "secure=;";
  }
};
if (
  (containsIdTokenHint || forgotPassword || cancelledForgotPassword) &&
  !containsIdToken
) {
   shutdown();
  sessionStorage.clear();
  localStorage.clear();
  cookieCleaner();
}

function App() {
  const authProvider = getAuthProvider();
  const { Provider } = UserContext;
  const user = useUser();

  const notistackRef = React.createRef() as any;
  const onClickDismiss = (key: string | number | undefined) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  if (containsIdTokenHint && idTokenHint) {
    authProvider.loginRedirect({
      scopes: getAppConfiguration().scopes,
      extraQueryParameters: {
        id_token_hint: idTokenHint.split("=")[1],
        login_hint: "",
      },
    });
  }
  useEffect(() => {
    if (user&&user?.email&&user?.name) {
      const secretKey = 'vryVgJW_O8ml59tSSO-TcH80AZ3wPN-I2l7nxWFW'
      const hmac = CryptoJS.HmacSHA256(user.id?.toString(), secretKey).toString(CryptoJS.enc.Hex);
      Intercom({
        app_id: 'zl8jjjnt',
       api_base: `https://api-iam.eu.intercom.io`,
       region: 'eu' ,
        user_id:user.id?.toString(),
        name: user.name,
        email: user.email.toString(),
        created_at: Math.floor(new Date().getTime() / 1000),
        custom_launcher_selector: '#launcher',
        user_hash: hmac,
         language_override: 'sv',
      });
    }
  }, [user]);

  return (
    <SnackbarProvider
      ref={notistackRef}
      action={(key) => (
        <IconButton onClick={onClickDismiss(key)}>
          <Close />
        </IconButton>
      )}
    >
      <MsalProvider instance={authProvider}>
        <Provider value={user}>
          <IntlProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <ConfirmationProvider>
                <AppContainer />
              </ConfirmationProvider>
            </MuiPickersUtilsProvider>
          </IntlProvider>
        </Provider>
      </MsalProvider>
    </SnackbarProvider>
  );
}

export default App;
